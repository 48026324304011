div#c-uploads {
  div#a-show {
    .artist-commentary {
      margin-top: 1em;
    }

    .commentary-translation {
      margin-top: 1em;
    }

    div#upload-guide-notice {
      margin-bottom: 2em;
    }

    ul#links {
      margin-bottom: 1em;
    }

    div#related-posts-by-source, div#iqdb-similar {
      display: inline-block;
    }

    div.upload_rating .radio label {
      font-weight: normal;
      display: inline;
      margin-right: 2em;

      input {
        margin-right: 0.5em;
      }
    }

    div.field_with_errors {
      display: inline;
    }

    #related-tags-container {
      margin-top: 1em;
    }
  }

  div#a-index {
    .info {
      margin-right: 1.5em;
    }
  }
}
