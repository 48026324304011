div#c-modqueue {
  div.post {
    padding: 1em;
    overflow: hidden;

    aside.column {
      @media (min-width: 660px) {
        padding-right: 1em;
      }
    }

    section.column {
      span.info {
        margin-right: 1.5em;
      }

      .quick-mod {
        display: inline-block;
        margin-bottom: 1em;
      }

      .post-flags-row {
        margin-top: 1em;
      }
    }
  }
}
