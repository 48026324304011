@import "../base/000_vars";

div#c-comments {
  div#a-index {
    div.header {
      margin-bottom: 1em;

      span.info {
        margin-right: 1.5em;
      }

      strong, time {
        margin-right: 0.3em;
      }
    }

    div.post {
      display: flex;
      margin-bottom: 4em;
    }
  }
}

form.edit_comment div.input.boolean {
  display: inline-block;

  label {
    font-weight: normal;
    vertical-align: initial;
  }
}

@media screen and (max-width: 660px) {
  div#c-comments div#a-index, div#c-comments div#a-show {
    div.post {
      display: block;
      margin: 2em 0;

      &:first-of-type {
        margin: 1em 0;
      }

      div.preview {
        margin: 1em auto;
        width: auto;
        height: auto;
      }
    }
  }
}
