div#c-static {
  div#a-keyboard-shortcuts {
    section.column {
      min-width: 25em;

      ul {
        margin-bottom: 2em;
      }

      li {
        margin-bottom: 0.5em;
      }
    }
  }
}
